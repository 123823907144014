
import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
    components: {
        CustomerInfo,
        PriceSummary,
        MemberCard,
        Receipt,
        DesktopPosNavbar
    },
    computed: {
        ...mapState({
            selectBranch: (state: any) => state.Store.selectBranch,
            selectPos: (state: any) => state.Store.selectPos
        }),
        ...mapGetters({
            net: 'Cart/net',
            cartTel: 'Cart/tel',
            exchangeTel: 'ExchangeOrder/tel',
            cartPayment: 'Cart/payment',
            exoPayment: 'ExchangeOrder/payment',
            exoNet: 'ExchangeOrder/desktopPayMore'
        })
    },
    methods: {
        ...mapActions({
            previewReceipt: 'Order/previewReceipt',
            createOrder: 'Cart/createOrder',
            setCashAmount: 'Cart/setCashAmount',
            createOrderExchange: 'ExchangeOrder/createOrder',
            setCashAmountExchange: 'ExchangeOrder/setCashAmount',
            previewExchangeReceipt: 'Order/previewExchangeReceipt'
        })
    }
})
export default class CardCheckout extends Vue {
  net!: any

  cartTel!: any

  exchangeTel!: any

  previewReceipt!: any

  preReceipt: any = null

  selectBranch!: any

  selectPos!: any

  createOrder!: any

  showPriceFormat!: any

  cartPayment!: any

  exoPayment!: any

  loading = false

  createOrderExchange!: any

  setCashAmountExchange!: any

  public readonly exoNet!: any

  previewExchangeReceipt!: any

  async mounted (): Promise<any> {
    if (this.$route.query?.type === 'exchange-order') {
      this.preReceipt = await this.previewExchangeReceipt({ status: 'no_print', ip: 'no print' })
    } else {
      this.preReceipt = await this.previewReceipt({ status: 'no_print', ip: 'no print' })
    }
  }

  get isExchangeOrder (): boolean {
    return this.$route.query?.type === 'exchange-order'
  }

  async checkout (): Promise<void> {
    this.loading = true
    let result: {
      success: boolean
      orderId: any
      message: string
    }
    if (this.isExchangeOrder) {
      result = await this.createOrderExchange()
    } else {
      result = await this.createOrder()
    }
    this.loading = false
    if (result.success) {
      this.$buefy.dialog.confirm({
        message: 'Create Order success',
        canCancel: false,
        onConfirm: () => this.$router.push({ name: 'OrderSuccess', query: { ...this.$route.query, orderId: result.orderId } })
      })
    } else {
      let message = ''
      if (result.message.includes('[00]')) {
        message += 'ของไม่พอ : '
      }
      const splitMessage = result.message.split(' - ')
      message += splitMessage[splitMessage.length - 1]
      message += '<br />กลับไปลบของในตะกร้าออกก่อน หรือ ติดต่อผู้จัดการสาขา'
      this.$buefy.dialog.alert({
        title: 'เกิดปัญหา',
        message
      })
    }
  }

  get sumAmount (): string {
    if (!this.preReceipt) return '-'
    return `${this.preReceipt.items.reduce((sum: number, d: any) => sum + d.amount, 0) }`
  }

  get payNet (): number {
    return this.$route.query?.type === 'exchange-order' ? this.exoNet : this.net ?? 0
  }

  get payment (): any {
    return this.$route.query?.type === 'exchange-order' ? this.exoPayment : this.cartPayment
  }

  goBack (): void {
    this.$router.push({ name: 'PaymentChannel', query: this.$route.query ?? {} })
  }

  get memberTel (): string {
    return this.isExchangeOrder ? this.exchangeTel : this.cartTel
  }
}
